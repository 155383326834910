//**************************************************************
//     User Context is to distrubite the state of the user     *
//     across all components on the app.                       *
//     Last updated on 11 July 2024 17:02 by Delight Jambani   *
//**************************************************************

import { create } from 'zustand';
import { jwtDecode } from "jwt-decode";
import {loginService, registerService} from '../services/authentication'

// Zustand store for auth
const userContext = create((set) => ({
  token: null,
  user: null,
  loading: false,
  signup: async (email,name, password) => {
    localStorage.removeItem('authToken');
    set({ loading: true });
    // Fake API call for login
    const response = await registerService(email,name,password);
    if (response.token) {
      const decodedToken = jwtDecode(response.token);
      console.log("Decorded Token :"+decodedToken);
      // Store both token and decoded user info in Zustand
      set({
        token: response.token,
        user: decodedToken,
      });

      // Optionally, store token in local storage for persistence
      localStorage.setItem('authToken', response.token);
      set({loading: false});
    }
  },
  login: async (email, password) => {
    set({ loading: true });
    // Fake API call for login
    const response = await loginService(email, password);
    if (response.token) {
      const decodedToken = jwtDecode(response.token);
      console.log("Decorded Token :"+decodedToken);
      // Store both token and decoded user info in Zustand
      set({
        token: response.token,
        user: decodedToken,
      });

      // Optionally, store token in local storage for persistence
      localStorage.setItem('authToken', response.token);
      set({loading: false});
    }
  },
  logout: () => {
    set({ token: null, user: null });
    localStorage.removeItem('authToken');
  },
  loadTokenFromStorage: () => {
    const token = localStorage.getItem('authToken');
    if (token) {
      const decodedToken = jwtDecode(token);
      set({ token, user: decodedToken });
    }
  },
  getTokenFromStorage: () => {
    const token = localStorage.getItem('authToken');
    if (token) {
      return token;
    }
  },
}));

export default userContext;