
// Implement signup with Firebase and react-bootstrap
// From Auth0 to firebase is the current change that seems feasible
// Updated by Delight Jambani 11 July 2024: 14:53


import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import UserContext from '../context/userContext.js'; // for managing user states
import { toast, Toaster } from "react-hot-toast"; // Toast 
import Loader from "../components/Loader";
import cubeLoading from "../assets/cubeLoading.gif";
import "./css/signup.css"


const SignUp = () => {

    // Get the registerUser function from the UserContext
    const { signup, user, loading } = UserContext();

    // Local state for email, name, and password
    const emailRef = useRef()
    const nameRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const [signupError, setSignupError] = useState('')

    // For redirecting after signing up 
    const navigate = useHistory();

    // Handle the form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        //check if passwords match to begin with
        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            toast.error('Passwords do not match')
            return
        }

        try {
            await signup(emailRef.current.value,nameRef.current.value, passwordRef.current.value);
            navigate.push("/profile");
        } catch {
            // console.log("Something went wrong" + error)
        }

    };

    return (
        <>
            <div className="login">
             {loading && (<Loader />)}
             {!loading && <img src={cubeLoading} alt="Rotating" />}
                <h1>Sign Up</h1>
                <form onSubmit={handleSubmit}>
                    <Toaster toastOptions={{ duration: 4000 }} />
                    <input type="email" name="u" placeholder="Email" required="required" ref={emailRef} className="formInput" />
                    <input type="text" name="name" placeholder="Full Name" required="required" ref={nameRef} className="formInput" />
                    <input type="password" name="p" placeholder="Password" required="required" ref={passwordRef} className="formInput" />
                    <input type="password" name="p" placeholder="Confirm Password" required="required" ref={passwordConfirmRef} className="formInput" />
                    <button type="submit" class="btn btn-primary btn-block btn-large" disabled={loading}>Sign up</button>
                </form>
                <div style={{ margin: "10px", fontSize: "15px" }}>
                    Already have an account? <Link to="/signin" >Sign in</Link>
                </div>
            </div>
        </>
    )
};

export default SignUp;