import React, { useRef, useMemo, useEffect, useState } from "react";
import { GoogleMap, LoadScript, MarkerF, Polygon, InfoWindow } from '@react-google-maps/api';
import { Link, useHistory } from "react-router-dom";
import backarrow from "../assets/left.png"
import close from "../assets/close_button.png";
import userlocation from "../assets/userlocation.png"
import house from "../assets/housing.webp"
import { CTA, Brand, Navbar, Slider } from "../components";
import Loader from "../components/Loader";
import {
    Footer,
    Blog,
    Possibility,
    Features,
    WhatGPT3,
    Header,
    Services,
} from "../containers";
import "./css/clientpage.css";

// Navigation component
const SearchAsset = () => {
    return (
        <div id="search">
            <svg viewBox="0 0 420 60" xmlns="http://www.w3.org/2000/svg">
                <rect className="bar" />

                <g className="magnifier">
                    <circle className="glass" />
                    <line className="handle" x1="32" y1="32" x2="44" y2="44"></line>
                </g>

                <g className="sparks">
                    <circle className="spark" />
                    <circle className="spark" />
                    <circle className="spark" />
                </g>

                <g className="burst pattern-one">
                    <circle className="particle circle" />
                    <path className="particle triangle" />
                    <circle className="particle circle" />
                    <path className="particle plus" />
                    <rect className="particle rect" />
                    <path className="particle triangle" />
                </g>
                <g className="burst pattern-two">
                    <path className="particle plus" />
                    <circle className="particle circle" />
                    <path className="particle triangle" />
                    <rect className="particle rect" />
                    <circle className="particle circle" />
                    <path className="particle plus" />
                </g>
                <g className="burst pattern-three">
                    <circle className="particle circle" />
                    <rect className="particle rect" />
                    <path className="particle plus" />
                    <path className="particle triangle" />
                    <rect className="particle rect" />
                    <path className="particle plus" />
                </g>
            </svg>
            <input type="search" name="q" aria-label="Search for inspiration" style={{ width: "319px" }} placeholder="Enter Asset Number" />
        </div>
    );
}

const slideData = [
    {
        index: 0,
        headline: "",
        button: "MINERALS",
        src: "https://cdn.pixabay.com/photo/2013/07/13/10/23/miner-157100_1280.png",
        link: "/banking",
    },
    {
        index: 1,
        headline: "",
        button: "AGRI PRODUCTS",
        src: "https://cdn3d.iconscout.com/3d/premium/thumb/smart-farm-application-6790862-5600930.png",
        link: "/commodity/agropower",
    },
    {
        index: 2,
        headline: "",
        button: "PROPERTY/LAND",
        src: "https://cdn3d.iconscout.com/3d/premium/thumb/living-room-6357418-5251972.png",
        link: "/contract",
    },
];

const dummyData = [
    {
        index: 0,
        name: "Delight Jambani",
        description: "Current Location",
        latitude: "",
        longitude: "",
        profile: ""
    },
]

const dummyAssetRepo = [
    {
        index: 0,
        name: "Cleverhill Investments",
        description: "300 squremeter clauster",
        lat: -17.738114,
        lng: 31.176154,
        profile: "/profile"
    },
]

const ClientScreen = () => {
    const [menu, setMenu] = useState(true)
    const [details, setDetails] = useState(false)

    const openMenu =() =>{
        setDetails(false)
        setMenu(true)
    }
    
    const Card = ({ role, title, description, buttonTitle, link }) => {
        const history = useHistory();

        const cardLink = (e) => {
            if (link === "company") {
                setMenu(false)
                setDetails(true)
            }
        }

        return (
            <div className="ServiceCardd" onClick={() => cardLink(link)}>
                {/* <div className={`ServiceCardd_img ${cardClass}`}>
              <div className="plus"><p>+</p></div>
            </div> */}
                <h3>{title}</h3>
                <div className="line"></div>
                <p>{description}</p>
                {/* <button className="cardsbuttonz" style={{ width: "45%", height: "10%", marginRight: "auto", marginLeft: "auto", marginBottom: "60px", background: "linear-gradient( 110.3deg,  rgba(73,93,109,1) 4.3%, rgba(49,55,82,1) 96.7% )" }}>{buttonTitle}</button> */}
            </div>
        )
    };

    const Viewport = () => (
        <div className="viewport" style={{ marginBottom: "60px", paddingTop: "200px", paddingBottom: "100px" }}>
            <SearchAsset />
            {details && <div class='dashcontainer' style={{ marginRight: "50%" }}>
                <div className='profilerow'>
                    <div className='stats-element'>
                        <img src={close} alt="close button" style={{ width: "35px", height: "35px", margin: "5px" }} onClick={openMenu} />
                        <div class='stats-container' id='stats-element'>
                            <div class='stats-table' style={{ zIndex: "100" }}>
                                <table class='performance-table' style={{ zIndex: "100" }}>
                                    <tbody className="table-body">
                                        <tr className="assetRow" style={{ background: "linear-gradient( 109.6deg,  rgba(0,0,0,0.93) 11.2%, rgba(63,61,61,1) 78.9% )" }}>
                                            <td class='table-desc'>NAME</td>
                                            <td>ID</td>
                                            <td>PHONE</td>
                                            <td>ASSET NAME</td>
                                            <td>ASSET NUMBER</td>
                                            <td>LATITUDE</td>
                                            <td>LONGITUDE</td>
                                        </tr>
                                        <tr
                                            className="assetRow" >
                                            <td class='table-desc'>Name</td>
                                            <td>2133444</td>
                                            <td>+2637000000</td>
                                            <td>share Number 223</td>
                                            <td>223</td>
                                            <td>-17.505033</td>
                                            <td>33.86743</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className='order-element' style={{ height: "60vh" }}>
                        <div class='element-header' style={{ background: "linear-gradient( 109.6deg,  rgba(0,0,0,0.93) 11.2%, rgba(63,61,61,1) 78.9% )", color: "#EEEEEE", padding: "24px" }}>LOGS</div>
                        <div class='order-scroll up' id='order-scrollup' ><i class="fa fa-chevron-up"></i></div>
                        <div class='order-container' id='order-container'>
                            <div class='order-item'>
                                <h1>Order #313-55416218</h1>
                                <p>Status: <span class='main-color'>Dispatched</span><br />Tracking Number: 1431 2349 11</p>
                                <p>Order Date: 12/24/16<br /> Customer Contact: random.guy@gmail.com</p>
                            </div>

                            <div class='order-item'>
                                <h1>Order #313-55416218</h1>
                                <p>Status: <span class='main-color'>Dispatched</span><br />Tracking Number: 1431 2349 11</p>
                                <p>Order Date: 12/24/16<br /> Customer Contact: random.guy@gmail.com</p>
                            </div>

                            <div class='order-item'>
                                <h1>Order #313-55416218</h1>
                                <p>Status: <span class='main-color'>Dispatched</span><br />Tracking Number: 1431 2349 11</p>
                                <p>Order Date: 12/24/16<br /> Customer Contact: random.guy@gmail.com</p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            }

            {menu && <div className="ServiceCardds">
                <Card
                    cardClass="ServiceCardd8"
                    title="Companies"
                    description="."
                    buttonTitle="Learn More"
                    link="company"
                />
                <Card
                    cardClass="ServiceCardd11"
                    title="Companies"
                    description="."
                    buttonTitle="Learn More"
                    link="company"
                />
                <Card
                    cardClass="ServiceCardd11"
                    title="Companies"
                    description="."
                    buttonTitle="Learn More"
                    link="company"
                />
            </div>
            }
        </div>
    );

    return (
        <>
            <div className="gradieant__bg">
                <Navbar />
            </div>
            <Viewport />
        </>
    )
}

export default ClientScreen;