import React from "react";
import { useDispatch } from "react-redux";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import { Link, useHistory } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import logo from "../../assets/logo1.png";
import UserContext from '../../context/userContext'; // for managing user states
import "./navbar.css";

const Navbar = () => {

   // Get the registerUser function from the UserContext
   const { logout, user,loading } = UserContext();

    // For redirecting after signing up 
    const navigate = useHistory();

  const handleLogout = async()=>{
    try {
      await logout();
      navigate.push("/");
  } catch {
      // console.log("Something went wrong" + error)
  }
  }
   
  const Menu = () => (
    <>
      <p>
        <Link to="/" className="nav_link">
          Home
        </Link>
      </p>
      <p>
        <Link to="/commodity/garlpex" className="nav_link">
         Asset Repo
        </Link>
      </p>
      <p>
        <Link to="/bazaar/page" className="nav_link">
          Market
        </Link>
      </p>
      {/* <p>
        <Link to="/banking" className="nav_link">
          Finance
        </Link>
      </p> */}
      {/* <p>
        <Link to="/banking" className="nav_link">
          Production
        </Link>
      </p> */}
    </>
  );

  const [toggleMenu, setToggleMenu] = React.useState(false);

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="gpt3__navbar-links_container">
          <Menu />
        </div>
      </div>
      { user ? (
        <div className="gpt3__navbar-sign">
          <p>
            <Link to="/profile">
              {user.name}
            </Link>
          </p>
          <button
            onClick={() => handleLogout()}
            style={{
              background: "#ff4820",
              color: "white",
              fontSize: "15px",
              borderRadius: "15px",
              padding: "15px",
              fontWeight: "normal",
            }}
          >
            Logout
          </button>
        </div>
      ) : (
        <div className="gpt3__navbar-sign">
          <p>
            <Link
              to="/signin"
              style={{
                background: "#ff4820",
                color: "white",
                fontSize: "15px",
                borderRadius: "15px",
                fontWeight: "normal",
                padding: "15px",
              }}
            >
              Signin
            </Link>
          </p>
        </div>
      )}

      <div className="gpt3__navbar-menu">
        {toggleMenu ? (
          <>
          <RiCloseLine
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)} 
          />
          </>
        ) : (
          <>
          <RiMenu3Line
            color="#ffff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
          </>
        )}

        {toggleMenu && (
          <div className="gpt3__navbar-menu_container scale-up-center">
            <div className="gpt3__navbar-menu_container-links">
              <Menu />
              {user ? (
                <div className="gpt3__navbar-menu_container-links-sign">
                  <p>
                    <Link to="/profile">
                       {user.name}
                    </Link>
                  </p>

                  <button
                    style={{
                      background: "#ff4820",
                      color: "white",
                      fontSize: "15px",
                      borderRadius: "15px",
                      padding: "10px",
                      fontWeight: "normal",
                    }}
                    onClick={() => handleLogout()}
                  >
                    Logout
                  </button>
                </div>
              ) : (
                <div className="gpt3__navbar-menu_container-links-sign">
                  <p>
                  <Link to="/signin">Sign in</Link>
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
