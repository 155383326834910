//**************************************************************
//     Authentication Service is to log the user and give back *
//     a token which will decipher to get user information.    *
//     Updated on 20 September2024 17:02 by Delight Jambani    *
//**************************************************************

// Import necessary modules (if axios is imported elsewhere, you can omit this)
import axios from 'axios';

// loginService handles user login by making a POST request with email and password
const loginService = async (email, password) => {
    // Configuration for the axios request, setting headers for JSON data
    const config = {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
    };

    try {
        // Make the API call to the login endpoint with email, password, and config
        const { data } = await axios.post(`/api/auth/login`, { email, password }, config);
        
        // If successful, return the response data (likely a token and user info)
        return data;
    } catch (error) {
        // Handle the error gracefully and log it to the console for debugging
        console.error("Error during login:", error);
        
        // Optionally, you could return a more informative message or rethrow the error
        throw new Error("Login failed. Please check your credentials and try again.");
    }
};


// loginService handles user register by making a POST request with email, name and password
const registerService = async (email,name,password) => {
    // Configuration for the axios request, setting headers for JSON data
    const config = {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
    };

    try {
        // Make the API call to the register endpoint with email, password, and config
        const { data } = await axios.post(`/api/auth/register`, { email,name,password }, config);
        
        // If successful, return the response data (likely a token and user info)
        return data;
    } catch (error) {
        // Handle the error gracefully and log it to the console for debugging
        console.error("Error during register:", error);
        
        // Optionally, you could return a more informative message or rethrow the error
        throw new Error("Login failed. Please check your credentials and try again.");
    }
};

// Export the loginService function for use in other parts of the application
export { loginService, registerService };
