import React from "react";
import Feature from "../../components/feature/Feature";
import { Link } from "react-router-dom";
import "./features.css";
import background from '../../assets/globe.png';

const Features = ({ featuresD, heading, linkUrl, linkText }) => (
  <div className="gpt3__features section__padding  bounce-in-top" id="features" style={{backgroundImage: `url(${background})`, backgroundRepeat:"no-repeat", backgroundSize: "contain"}}>
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">{heading}</h1>
      <Link
        to={linkUrl}
        style={{
          backgroundColor: "orangered",
          color: "white",
          padding: "5px 40px",
          borderRadius: "15px",
        }}
      >
        {linkText}
      </Link>
    </div>
    <div className="gpt3__features-container" style={{background:  "linear-gradient( 109.6deg,  rgba(0,0,0,0.93) 11.2%, rgba(63,61,61,1) 78.9% )", paddingLeft: "20px", paddingRight: "10px"}}>
      {featuresD.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
