// Import the functions you need from the SDKs you need
import { initializeApp, getApps, getApp } from "firebase/app";
 
import {getAuth} from'firebase/auth'
import {getFirestore} from '@firebase/firestore'
import {getStorage} from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC9mCPLzqsyjVuy687QTG8_02Vb8HoSkl0",
  authDomain: "made-in-africa-212b0.firebaseapp.com",
  projectId: "made-in-africa-212b0",
  storageBucket: "made-in-africa-212b0.firebasestorage.app",
  messagingSenderId: "37085962450",
  appId: "1:37085962450:web:b095a22e0f51b3af260d0b",
  measurementId: "G-9EY17PCMKZ"
};

// Initialize Firebase
const app = getApps().length === 0 ? initializeApp(firebaseConfig): getApp()
export const auth = getAuth(app);
export const db = getFirestore(app)
export const storage=getStorage(app)
