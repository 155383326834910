import React from "react";
import "./header.css";
import { Link } from "react-router-dom";
import people from "../../assets/people.png";
import coal from "../../assets/coal2.png";
import globe from "../../assets/goldearth.png";
import megajoule from "../../assets/megajoule.jpg";
import approval from "../../assets/approved1.png";
import asset from "../../assets/assetRepo.png";

let image1 = "";
let house =
  "https://cdn.pixabay.com/photo/2022/04/11/23/57/house-7126876_1280.png";
const Header = ({ content, image, heading, color }) => {
  if (image === "coal") {
    image1 = coal;
  } else if (image === "house") {
    image1 = house;
  } else if (image === "globe") {
    image1 = globe;
  } else if (image === "megajoule") {
    image1 = megajoule;
  } else if (image === "coins") {
    image1 = "https://images.unsplash.com/photo-1639322537228-f710d846310a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1032&q=80";
  } else if (image === "approval") {
    image1 = approval;
  } else if (image === "housefin") {
    image1 = "https://cdn3d.iconscout.com/3d/premium/thumb/bank-interest-4086294-3379627.png";
  } else if (image === "asset") {
    image1 = asset;
  } else if (image === "agropower") {
    image1 = "https://cdn3d.iconscout.com/3d/premium/thumb/smart-farm-application-6790862-5600930.png";
  } else if (image === "paymentresult") {
    image1 = "https://cdn3d.iconscout.com/3d/premium/thumb/payment-successful-5680095-4731409.png"
  } else if (image === "syndicateDash") {
    image1 = "https://cdn3d.iconscout.com/3d/premium/thumb/fluctuation-7811085-6184559.png?f=webp"
  } else {
    image1 = image
  }

  return (
    <>
      <div className="gpt3__header section__padding bounce-in-top" style={{ background: "radial-gradient( circle farthest-corner at -8.9% 51.2%,  rgba(255,124,0,1) 0%, rgba(255,124,0,1) 15.9%, rgba(255,163,77,1) 15.9%, rgba(255,163,77,1) 24.4%, rgba(27, 27, 27,0.2) 24.5%, rgba(52, 52, 52,0.2) 66% )", padding: "20px" }}>
        <div className="gpt3__header-content">
          <h1 className="gradient__text tracking-in-expand" style={{ padding: "20px", textAlign: "left" }}>{heading}</h1>
          <p style={{ padding: "20px" }}>{content}</p>

          <div className="gpt3__header-content__input"></div>

          {/* <div classNameName="gpt3__header-content__people">
            <img src={people} alt="people" />
            <p>1,600 people requested access a visit in last 24 hours</p>
          </div> */}
        </div>
        <div className="gpt3__header-image bounce-top" >
          <img src={image1} style={{ width: "80vh" }} className="headImg" alt="coal" />
        </div>

        <div
          data-tf-sidetab="DRCw3MJl"
          data-tf-opacity={100}
          data-tf-iframe-props="title=HOUSEFIN (PVT) LTD MEGAJOULE AXIS"
          data-tf-chat
          data-tf-transitive-search-params
          data-tf-button-color="#0460AF"
          data-tf-button-text="Try me!"
          style={{ all: "unset" }}
        />
      </div>
      {/* <script src="//embed.typeform.com/next/embed.js"></script> */}
    </>
  );
};

export default Header;
