import React, { useState, useEffect } from "react";
import { CTA, Brand, Navbar, Minerals } from "../components";
import { Link, useHistory } from "react-router-dom";
import Loader from "../components/Loader";
import { toast, Toaster } from "react-hot-toast";
import companiesContext from '../context/companiesContext';




const ProfileScreen = () => {
   // Get the signIn function from the UserContext
   const { getCompanies,companies,loading,setRole } = companiesContext();

  const Card = ({ role, title, description, buttonTitle, link }) => {

    const history = useHistory();

    const cardLink = () => {
      setRole(role)
      history.push(link);
    }

    return (
      <div className="ServiceCardd">
        {/* <div className={`ServiceCardd_img ${cardClass}`}>
          <div className="plus"><p>+</p></div>
        </div> */}
        <h3>{title}</h3>
        <div className="line"></div>
        <p>{description}</p>
        <button onClick={cardLink} className="cardsbuttonz" style={{ width: "45%", height: "10%", marginRight: "auto", marginLeft: "auto", marginBottom: "60px", background: "linear-gradient( 110.3deg,  rgba(73,93,109,1) 4.3%, rgba(49,55,82,1) 96.7% )" }}>{buttonTitle}</button>
      </div>
    )
  };

  const Viewport = ({companz}) => (
    <div className="viewport" style={{ marginBottom: "60px" }}>
     <div className="ServiceCardds">
        {companz.map((company, index) => (
          <Card 
           key={company.companyID}
           cardClass="ServiceCardd10"
           title={company.company}
           description={company.companyEmail}
           buttonTitle={company.role}
           link={`/company/${company.companyID}`}
           role={company.role}
         />
        ))}
       
        <Card
          cardClass="ServiceCardd8"
          title="Companies"
          description="."
          buttonTitle="Learn More"
          link="/company/page"
        />
        <Card
          cardClass="ServiceCardd11"
          title="Companies"
          description="."
          buttonTitle="Learn More"
          link="/company/page"
        />
      </div> 
    </div>
  );

  useEffect(() => {
     getCompanies()
     console.log("COMPANIES "+JSON.stringify(companies))
  }, []);

  return (

    <>
      <div className="gradieant__bg">
        <Navbar />
      </div>
      <Toaster toastOptions={{ duration: 4000 }} />
      {companies? <Viewport companz={companies}/> :
       <h1 style={{color: "orange", textAlign: "center", fontSize: "50px"}}>No Companies Found</h1> } 
    </>
  );
};

export default ProfileScreen;