import React, { useRef, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import HousingScreen from "./screens/HousingScreen";
import GrowthSeekingScreen from "./screens/GrowthSeekingScreen";
import DashboardPScreen from "./screens/DashboardPScreen";
import ProfileScreen from "./screens/ProfileScreen"
import BazaarScreen from "./screens/BazaarScreen";
import BankingHallScreen from "./screens/BankingHallScreen";
import "./App.css";
import TradingHallScreen from "./screens/TradingHallScreen";
import DocusignScreen from "./screens/DocusignScreen";
import ProtectedRoute from "./screens/ProtectedRoute";
import SignUp from "./screens/Signup";
import SignIn from "./screens/Signin";
import ClientScreen from "./screens/ClientScreen";
import UserContext from './context/userContext';

const App = () => {
  const loadTokenFromStorage = UserContext((state) => state.loadTokenFromStorage);

  useEffect(() => {
    loadTokenFromStorage(); // Load token when app starts
  }, [loadTokenFromStorage]);

  return (
      <Router>
        <Switch>
          <Route path="/bazaar/page" component={BazaarScreen} />
          <Route path="/tradinghall" component={TradingHallScreen} />
          <Route path="/banking" component={BankingHallScreen} />
          <Route path="/signup" component={SignUp} />
          <Route path="/signin" component={SignIn} />
          <Route path="/commodity/garlpex" component={GrowthSeekingScreen} />
          <Route path="/docusign" component={DocusignScreen} />
          <Route path="/client/page" component={ClientScreen} />
          <ProtectedRoute path="/company/:id" component={DashboardPScreen} exact />
          <Route path="/" component={HomeScreen} exact />
          <Route path="/housing" component={HousingScreen} exact />
          {/* <Route path="/banking" component={BankingHallScreen} exact /> */}
          <ProtectedRoute exact path="/profile" component={ProfileScreen} />
        </Switch>
      </Router>
  );
};

export default App;
