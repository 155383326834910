import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import UserContext from '../context/userContext.js'; // for managing user states
import Loader from "../components/Loader"; // Component to show while authentication is in progress

const ProtectedRoute = ({ component: Component, ...rest }) => {

  // Get the signIn function from the UserContext
  const {user,loading } = UserContext();


  return (
    <Route
      {...rest}
      render={(props) => {
        return user ? <Component {...props} /> : <Redirect to ="/signin" />; // If user is logged in, render the component, else redirect to login page
      }}
    />
  );
};

export default ProtectedRoute;
