//**************************************************************
//     Companies Service is to get companies associated with   *
//     the user.                                                *
//     Updated on 20 September2024 17:02 by Delight Jambani    *
//**************************************************************

// Import necessary modules (if axios is imported elsewhere, you can omit this)
import axios from 'axios';
import UserContext from '../context/userContext.js';

// For redirecting after signing up 
const { getTokenFromStorage, logout } = UserContext.getState();
// loginService handles user login by making a POST request with email and password
const getCompaniesService = async () => {
    // Configuration for the axios request, setting headers for JSON data
    const token = getTokenFromStorage()
    console.log("TOKEN FOUND " + token)
    if (!token) {
        return
    }
    const config = {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: `Bearer ${token}`,  // Attach token to Authorization heade
        },
    };

    try {
        // Make the API call to the login endpoint with email, password, and config
        const { data } = await axios.post(`/api/protected/companies/get`, {}, config);
        return data;
    } catch (error) {
        // Handle the error gracefully and log it to the console for debugging
        console.error("Error during login:", error.response.status);
        if (error.response.status === 401 || error.response.status === 403) {
            // Token is invalid or expired
            logout();
            // Optionally redirect to login
            return;
        }
    }
};

const getAssetsService = async (companyID) => {
    // Configuration for the axios request, setting headers for JSON data
    const token = getTokenFromStorage()
    if (!token) {
        return
    }
    const config = {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: `Bearer ${token}`,  // Attach token to Authorization heade
        },
    };

    try {
        // Make the API call to the login endpoint with email, password, and config
        const { data } = await axios.post(`/api/protected/companies/assets`, { companyID }, config);
        return data;
    } catch (error) {
        // Handle the error gracefully and log it to the console for debugging
        if (error.response.status === 401 || error.response.status === 403) {
            // Token is invalid or expired
            logout();
            // Optionally redirect to login
            return;
        }
        if (error.response.status === 404 || error.response.status === 500) {
            console.log("Error retrieving company assets")
        }
    }
};

const getEventService = async (assetCategoryID, companyID) => {
    // Configuration for the axios request, setting headers for JSON data
    const token = getTokenFromStorage()
    if (!token) {
        logout()
        return
    }

    const config = {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: `Bearer ${token}`,  // Attach token to Authorization heade
        },
    };

    try {
        // Make the API call to get events
        const { data } = await axios.post(`/api/protected/companies/get/events`, { assetCategoryID, companyID }, config);
        return data;
    } catch (error) {
        console.log("ERROR :" + error)
        // Handle the error gracefully and log it to the console for debugging
        if (error.response.status === 401 || error.response.status === 403) {
            // Token is invalid or expired
            // logout();
            console.log("ERROR :" + error.message)
            // Optionally redirect to login
            return;
        }
        if (error.response.status === 404 || error.response.status === 500) {
            console.log("Error retrieving asset events")
        }
    }
};

const addEventService = async (companyID, eventName, description, assetCategoryID, assetValue, receipts, eventLoop, installment) => {
    // Configuration for the axios request, setting headers for JSON data
    const token = getTokenFromStorage()
    if (!token) {
        logout()
        return
    }

    const config = {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: `Bearer ${token}`,  // Attach token to Authorization heade
        },
    };

    try {
        // Make the API call to get events
        const { data } = await axios.post(`/api/protected/companies/create/event`, {companyID, eventName, description, assetCategoryID, assetValue, receipts, eventLoop, installment}, config);
        return data;
    } catch (error) {
        console.log("ERROR :" + error)
        // Handle the error gracefully and log it to the console for debugging
        if (error.response.status === 404 || error.response.status === 500) {
            // Token is invalid or expired
            // logout();
            console.log("ERROR :" + error.message)
            // Optionally redirect to login
            return;
        }
        if (error.response.status === 403 || error.response.status === 407) {
            console.log("User does not have permission to create an event")
            logout();
        }
    }
}
export { getCompaniesService, getAssetsService, getEventService, addEventService }