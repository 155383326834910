import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from '@auth0/auth0-react';
import 'bootstrap/dist/css/bootstrap.min.css';

const options = {
  position: "top center",
  timeout: 12000,
  offset: "30px",
  transition: "scale",
};

// const auth0Config = {
//   domain: 'dev-p31ciya5dsgdlkqx.us.auth0.com',
//   clientId: 'I8kMuHnxKmqfSyHzZvV9tQRSQDUVrg1w',
//   redirectUri: window.location.origin + "/portal",
//   audience: 'https://made-in-africa-mining-intiative-api.com',
//   scope: 'openid profile email'
// };

ReactDOM.render(
    <AlertProvider template={AlertTemplate} {...options}>
         {/* Context that's working with firebase states */}
          <App />  {/* Render App directly */}
    </AlertProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();