import React, { useRef, useState, useEffect } from "react";
import { Footer, Possibility, Features, Header, WhatGPT3 } from "../containers";
import { CTA, Brand, Navbar, Slider } from "../components";
import { Link } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from "react-router-dom";
import { auth } from "../firebase";
import { RecaptchaVerifier, PhoneAuthProvider, signInWithPhoneNumber } from "firebase/auth";
import userContext from "../context/userContext";
import { BsFillShieldLockFill, BsTelephoneFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import OtpInput from "otp-input-react";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import mega from "../assets/mega.png";
import rental from "../assets/Rental.png";
import housefin from "../assets/housefin2.png";
import housefinlogo from "../assets/housefinlogo1.png";
import megajouleToken from "../assets/Token.png";
import Loader from "../components/Loader";
import "./css/housefin.css";
import './css/authentication.css'




const BankingHallScreen = ({ match }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [showPhone, setShowPhone] = useState(true);
  const [approved, setApproved] = useState(false);

  const sendPhone = async (phoneNumber, userEmail) => {
    // if (isAuthenticated) {
    //   try {
    //     const accessToken = await getAccessTokenSilently();
    //     await fetch(`/identification/phone`, {
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/json',
    //         Authorization: `Bearer ${accessToken}`,
    //       },
    //       body: JSON.stringify({ phoneNumber, userEmail }),
    //     });
    //   } catch (error) {
    //     console.error('Error saving user phone:', error.message);
    //   }
    // }
  };

  const Authentication = () => {
    const [otp, setOtp] = useState("");
    const [ph, setPh] = useState("");
    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationId, setVerificationId] = useState(null);
    const [verificationCode, setVerificationCode] = useState('');
    const [error, setError] = useState(null);

    const sendVerificationCode = async () => {
      let attemptCount = 0;
      let backoffDelay = 1000; // Initial delay in milliseconds

      const sendRequest = async () => {
        try {
          setLoading(true);
          // setError(null);

          // Create a reCAPTCHA verifier
          const appVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
            size: 'invisible',
            callback: () => {
              console.log('recaptcha resolved..')
            }
          });

          const formatPh = "+" + phoneNumber;
          console.log("appVerifier :" + JSON.stringify(appVerifier))
          console.log("auth :" + JSON.stringify(auth))
          // Send verification code
          const confirmationResult = await signInWithPhoneNumber(auth, formatPh, appVerifier);
          setVerificationId(confirmationResult.verificationId);
          setLoading(false);
        } catch (error) {
          console.error("Error sending verification code:", error);
          setLoading(false);
          // setError(error.message);
        }

        
      };
 
      sendRequest(); // Start the initial request
    };

    const verifyCode = async () => {
      try {
        setLoading(true);
        // setError(null);

        // Verify the code
        const credential = await PhoneAuthProvider.credential(verificationId, verificationCode);
        await auth.signInWithCredential(credential);
        setLoading(false);
        // User is signed in
        console.log('User signed in:', auth.currentUser);
      } catch (error) {
        console.error("Error verifying code:", error);
        setLoading(false);
        // setError(error.message);
      }
    };


    // const onSignup = () => {
    //   setLoading(true);
    //   onCaptchVerify();

    //   const appVerifier = window.recaptchaVerifier;
    //   const formatPh = "+" + ph;
    //   let attemptCount = 0;
    //   let backoffDelay = 1000; // Initial delay in milliseconds

    //   const sendVerificationCode = () => {
    //     signInWithPhoneNumber(auth, formatPh, appVerifier)
    //       .then((confirmationResult) => {
    //         window.confirmationResult = confirmationResult;
    //         setLoading(false);
    //         setShowOTP(true);
    //         toast.success("OTP sent successfully!");
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //         attemptCount++;
    //         backoffDelay *= 2; // Double the delay for each attempt
    //         setTimeout(() => {
    //           sendVerificationCode(); // Retry after the delay
    //         }, backoffDelay);
    //         setLoading(false);
    //       });
    //   };

    //   sendVerificationCode(); // Start the initial request
    // };


    // const onOTPVerify = () => {
    //   setLoading(true);
    //   window.confirmationResult
    //     .confirm(otp)
    //     .then(() => {
    //       setApproved(true);
    //       sendPhone("+" + ph, user.email);
    //       setShowPhone(false);
    //       setLoading(false);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       setLoading(false);
    //     });
    // };

    return (
      <div className="vid-container">
        <video className="bgvid" autoPlay muted loop>
          <source src="https://videocdn.cdnpk.net/joy/content/video/free/video0471/large_preview/_import_619c37dd58d742.19860068.mp4" type="video/webm" />
        </video>
        <div className="inner-container">
          <video className="bgvid inner" autoPlay muted loop>
            <source src="https://videocdn.cdnpk.net/joy/content/video/free/video0471/large_preview/_import_619c37dd58d742.19860068.mp4" type="video/webm" />
          </video>
          <div className="box">
            {/* <div id="recaptcha-container"></div> */}
            {verificationId ? (
              <>
                <div className="otp-container">
                  <BsFillShieldLockFill size={30} />
                  <label className="otp-label">
                    Enter your OTP
                  </label>
                  <OtpInput value={verificationCode} onChange={setVerificationCode} OTPLength={6} otpType="number" autoFocus className="opt-container" />
                  <button onClick={verifyCode} className="otp-button">
                    {loading && <CgSpinner size={20} className="animate-spin" />}
                    Verify OTP
                  </button>
                </div>
              </>
            ) : (
              <>
                <BsTelephoneFill size={30} />
                <label className="phone-label">Verify with Phone</label>
                <PhoneInput country={"zw"} value={phoneNumber} onChange={setPhoneNumber} />
                <button onClick={sendVerificationCode} className="phone-button">
                  {loading && <CgSpinner size={20} className="animate-spin" />}
                  Verify Mobile Number
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {loading && <Loader />}
      {showPhone && <Authentication />}
      
    </>
  );
};

export default BankingHallScreen;
